import * as React from 'react';
import styled from 'react-emotion';

import Layout from '../components/Layout';
import { Shapes } from '../components/Shapes';
import map from '../images/map.svg';
import me from '../images/me.jpg';
import { SEO } from '../components/SEO';
import { Footer } from '../components/Footer';

const Container = styled.div`
  padding: 0 3rem;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 3rem;
  @media (max-width: 800px) {
    padding: 0 2rem;
    margin-bottom: 0rem;
  }
`;

const Address = styled.address`
  font-family: 'Inconsolata', monospace;
  text-decoration: none;
  font-style: normal;
  font-size: 26.4229px;
  line-height: 162%;
  margin-right: 3rem;
  color: #626262;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    display: block;
    svg {
      max-width: 150%;
    }
  }
`;

const ContactsSection = styled(Section)`
  align-items: flex-start;
`;

const Main = styled.main`
  flex-grow: 1;
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 107.4%;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  color: #3f3f3f;
  @media (max-width: 400px) {
    font-size: 40px;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 169.69%;
  color: rgba(51, 51, 51, 0.8);
  margin-top: 0;
  margin-bottom: 2rem;
`;

const SubsectionTitle = styled(Title)`
  text-transform: none;
  font-size: 48px;
  font-weight: 600;
  line-height: 135%;
  margin: 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const Text = styled.article`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  max-width: 700px;
  line-height: 2rem;
  color: #626262;
  min-width: 250px;
  position: relative;
  z-index: 1;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

const Map = styled.img`
  max-width: 400px;
  @media (max-width: 800px) {
    margin-top: 2rem;
  }
`;
const Me = styled.img`
  max-width: 160px;
  border-radius: 100%;
  align-self: center;
  transition: all 600ms;
  &:hover {
    transform: scale(1.2, 1.2);
  }
  @media (max-width: 600px) {
    max-width: 100px;
    align-self: flex-start;
  }
`;

const Column = styled.div`
  width: 50%;
  flex-shrink: 0;
  @media (max-width: 550px) {
    width: auto;
  }
`;
const Columns = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 550px) {
    display: block;
  }
`;
const Aside = styled.aside`
  margin-left: 2rem;
`;

const IndexPage = () => (
  <Layout>
    <SEO />
    <Container>
      <Section>
        <Main>
          <TitleContainer>
            <Title id="konsultointi">
              Riku
              <br />
              Rouvila
            </Title>
            <Me src={me} />
          </TitleContainer>
          <Text>
            Hello there 👋 I'm a freelance Software Consultant / Developer
            living and working in beautiful Helsinki, Finland. I've worked as a
            full-time software developer since 2012 for various tech
            consultancies, such as Leonidas, Futurice and TietoEVRY, as a
            developer, lead developer, tech lead and a Scrum master. <br />
            <br />
            If you're interested in hiring me, send me an email or connect via{' '}
            <a href="https://www.linkedin.com/in/rikurouvila/">LinkedIn</a>. For
            my latest thoughts on the software development scene, check out our
            podcast 🎙 <a href="https://webbidevaus.fi">Webbidevaus.fi</a>.<br />
            <br />
          </Text>
        </Main>
      </Section>
    </Container>

    <Container>
      <Section>
        <Columns>
          <Column>
            <Text>
              <strong>As part of your team, I can</strong>

              <ul>
                <li>
                  Lead and support your cross-functional team
                  <br /> while doing hands-on development work
                </li>
                <li>
                  Guide your team in architectural and other technical
                  discussions
                </li>
                <li>
                  Facilitate Scrum meetings & process as a<br /> Scrum master
                </li>
              </ul>
            </Text>
          </Column>
          <Column>
            <Text>
              <strong>As an individual contributor, I can</strong>
              <ul>
                <li>Fix non-functioning code with a quick deadline</li>
                <li>
                  Build functional prototypes quickly and help you define the
                  scope
                </li>
                <li>
                  Organize hands-on workshops and training sessions about
                  technical topics
                </li>
              </ul>
            </Text>
          </Column>
        </Columns>
      </Section>
    </Container>

    <Container>
      <Section>
        <Text>
          <Subtitle>
            I'm currently using and
            <br /> interested in
          </Subtitle>
          <ul>
            <li>Typescript</li>
            <li>Node.js</li>
            <li>Next.js</li>
            <li>GraphQL</li>
            <li>React & React Native</li>
            <li>Progressive web apps</li>
            <li>Serverless</li>
            <li>ReasonML</li>
            <li>No-code automation platforms</li>
            <li>Postgraphile, Hasura</li>

            <li>Monorepos</li>
            <li>Azure & AWS</li>
            <li>Docker & Kubernetes</li>
            <li>Electron apps</li>
          </ul>
        </Text>
        <Aside>
          <Shapes />
        </Aside>
      </Section>
    </Container>

    <Container>
      <SubsectionTitle id="yhteystiedot">Contact me</SubsectionTitle>

      <ContactsSection>
        <Main>
          <Address>
            <strong>RIKU ROUVILA</strong>
            <br />
            riku@okaycodes.fi
            <br />
            +358 50 4700 715
            <br />
            Helsinki, Finland
            <br />
            <a href="https://www.linkedin.com/in/rikurouvila/">LinkedIn</a>{' '}
            <a href="https://twitter.com/rikurouvila">Twitter</a>{' '}
            <a href="https://github.com/rikukissa">Github</a>
          </Address>
        </Main>
        <aside>
          <Map src={map} alt="Helsinkiin osoittava kartta" />
        </aside>
      </ContactsSection>
    </Container>
    <Footer />
  </Layout>
);

export default IndexPage;
